<template>
  <div>
    <van-row type="flex">
      <van-button size="small" round :type="selectStates[idx]? 'info': 'default'" 
        v-for="(item, idx) in titles" :key="idx" @click="selectItem(idx)"
        style="margin:10px"
        >
        <span>{{item.value}}</span>
      </van-button>
    </van-row>
  </div>
</template>

<script>
export default {
  modal: {
    prop: 'selection',
    event: 'change'
  },
  props: {
    titles: {
      type: Array,
      default: () => []
    },
    selection: {
      type: Array,
      default: () => []
    },
    selectStates: {
      type: Array,
      default: () => []
    }
  },
  created() {
    for(let i=0;i<this.titles.length;i++) {
      this.selectStates.push(false)
    }
  },
  methods: {
    selectItem(idx) {
      let _self = this
      this.selectStates.splice(idx, 1, !this.selectStates[idx])
      let result = []
      this.selectStates.forEach((state, index) => {
        if(state) {
          result.push(_self.titles[index].id)
        }
      })
      this.$emit('selection-update', result)
    }
  }
}
</script>

<style>

</style>