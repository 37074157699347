<template>
  <div style="min-height:100vh;">
    <titleLink title="行程详情" :onClick="onClickBack"></titleLink>
    <div style="height:95vh;overflow-y:scroll;padding:.2rem .2rem 2rem .2rem;margin-top:.1rem">
      <card style="margin:30px 0 100px">
        <template #content>
          <guideInfo :guider="guide" :tour="currentTravelList"></guideInfo>
          <van-divider/>
          <tourInfoTop :showNodesButton="false" :tour="currentTravelList"></tourInfoTop>
          <van-row class="row" style="margin-top: 10px">
            出发日期：{{ currentTravelList.start_date }}
          </van-row>
          <van-row class="row"> 团队人数：{{ currentTravelList.tourists_count == null? 0: currentTravelList.tourists_count }} </van-row>
          <van-divider/>
          <div class="circle-left"></div>
          <div class="circle-right"></div>
          <van-row>
            <span class="tour_title">
              {{currentTravelList.travel_plan.plan_name}}
            </span>
          </van-row>
          <van-steps :active="currentNodeIdx" active-color="#395EE8" direction="vertical" active-icon="clock">
            <van-step v-for="(item, index) in currentTravelList.travel_list_nodes" :key="index">
                <van-row>
                <van-col span="20" >
                  <span :style="{'font-weight':600, 'color': currentTravelList.status == 3 && index > currentNodeIdx - 1? '#969799': 'inherit'}">
                    {{item.node_name}}
                  </span>
                </van-col>
              <van-col span="4" style="text-align:right;padding-left:5px">
                  <van-tag round color="#13C2C220" text-color="#13C2C2" style="width:50px"
                    size="medium" v-if="index < currentTravelList.travel_checkins.length 
                      && currentTravelList.travel_checkins.length > 0">已完成</van-tag>
                  <van-tag round color="#3672F920" text-color="#3672F9" 
                    size="medium" v-if="index === currentTravelList.travel_checkins.length && currentTravelList.status == 1" style="width:50px">进行中</van-tag>
                  <van-tag round  
                    size="medium" type="danger" color="#6f6f6f23" text-color="#979797"
                    v-if="index > currentNodeIdx - 1 && currentTravelList.status == 3" 
                    style="width:50px">已取消</van-tag>
                </van-col>
              </van-row>
              <van-row :style="{'margin-top':'.1rem', 'color':currentTravelList.status == 3 && index > currentNodeIdx - 1? '#969799': 'inherit'}">
                <span>{{item.node_description}}</span>
              </van-row>
              <van-row :style="{'margin-top':'.1rem', 'color':currentTravelList.status == 3 && index > currentNodeIdx - 1? '#969799': 'inherit'}">
                <span>开始：{{item.start_time}}</span>
              </van-row>
              <van-row :style="{'margin-top':'.1rem', 'color':currentTravelList.status == 3 && index > currentNodeIdx - 1? '#969799': 'inherit'}">
                <span>结束：{{item.end_time}}</span>
              </van-row>
            </van-step>
          </van-steps>
        </template>
      </card>
    </div>
    
    <van-action-sheet v-model="showFeedbackPanel" title="" :closeable="false">
      <div class="content">
        <van-row>
          <span style="font-weight:600;font-size:20px">
            匿名评价{{currentFeedbackTypeText}}
          </span>
        </van-row>
        <van-row style="padding:30px">
          <van-rate
            gutter="20"
            v-model="feedbackDetail.rate_star"
            :size="25"
            allow-half
            color="#ffd21e"
            void-icon="star"
            void-color="#eee"
          />
        </van-row>
        <multiSelectTags :titles.sync="filtered_feedback_tags" 
          v-on:selection-update="onSelectionUpdate" :selectStates="feedback_selection_states"></multiSelectTags>
        <van-row type="flex">
          <van-field type="textarea" label="" rows="3"  
            placeholder="还有什么要补充的，可以写在这里～" v-model="feedbackDetail.feedback_content"/>
        </van-row>
        <van-row>
          <van-button type="info" @click="submitFeedback()" class="action_btn" color="#395EE8">提交</van-button>
        </van-row>
      </div>
    </van-action-sheet>
    <div class="action_bg" v-cloak v-if="showJoinTourBtn">
      <van-button type="info" class="action_btn" color="#395EE8" round @click="joinTour">加入该行程</van-button>
    </div>
  </div>
</template>

<script>
import card from "@/views/components/Card";
import guideInfo from "@/views/components/GuideInfo";
import tourInfoTop from "@/views/components/TourInfoTop"
import {mapGetters} from 'vuex'
import multiSelectTags from "@/views/components/MultiSelectTags"
import api from '@/services/apis.js'
import { Toast, Dialog } from 'vant';
import titleLink from "@/views/components/TitleLink"
export default {
  components: {
    card,
    guideInfo,
    tourInfoTop,
    multiSelectTags,
    titleLink
  },
  data: () => ({
    feedbackDetail: {
      feedback_type: '1',
      feedback_content: "",
      rate_star: 0,
      feedbackable_type: "Guide",
      feedbackable_id: 0,
      feedback_type: 1,
      travel_list_id: 0,
      tag_ids: [],
    },
    showFeedbackPanel: false,
    filtered_feedback_tags: [],
    feedback_selection_states: [],
    feedbackTypeSelectShow: false,
    feedbackTypeSelectActions: [
      {name: '导游', value: 'Guide'},
      {name: '行程', value: 'TravelList'},
      {name: '旅行社', value: 'TravelCompany'},
    ],
    currentFeedbackTypeText: "",
  }),
  computed: {
    ...mapGetters('travel_list', ['currentTravelList', 'allTours', 'onGoingTours']),
    ...mapGetters('feedback', ['feedback_tags']),
    ...mapGetters('guider', {
      guide: 'getCurrentGuider'
    }),
    showJoinTourBtn() {
      let result = true
      for(let i=0;i<this.allTours.length;i++){
        const tour = this.allTours[i]
        if(tour.id == this.$route.params.id) {
          result = false
        }
      }
      if(this.currentTravelList.status === 3 || this.currentTravelList.status === 2) {
        result = false
      }
      return result && this.onGoingTours.length == 0
    },
    currentNodeIdx() {
      if(this.currentTravelList.status === 0 && this.currentTravelList.travel_checkins.length === 0) {
        return -1
      }
      return this.currentTravelList.travel_checkins.length
    },
  },
  created() {
    const tourId = this.$route.params.id
    const _self = this
    this.$store.dispatch('travel_list/getAllTravelLists', '0,1,2,3')
    this.$store.dispatch('travel_list/getAllTravelLists', 1)
    this.$store.dispatch('feedback/get_feedback_tags')
    // this.feedbackDetail.feedbackable_id = this.currentTravelList.guider.id
    this.$store.dispatch('travel_list/getTravelListInfoById', tourId).then(function(res){
      _self.$store.dispatch('guider/getGuiderById', res.data.guide_id)
      _self.$store.dispatch('wode/getPersonInfo').then(function(personInfo) {
        let isInTour = false
        res.data.tourists.forEach((tourist) => {
          if(tourist.id == personInfo.user.id) {
            isInTour = true
          }
        })
        if(res.status == 2 && !isInTour) {
            Toast('您不能加入已完成的团')
            _self.$router.push('/')
        }
        if(res.status == 3 && !isInTour){
          Toast('您不能加入已取消的团')
          _self.$router.push('/')
        }
      })
    })
  },
  methods: {
    onClickBack() {
      this.$router.push('/tour/main');
    },
    submitTourRate() {
      this.showTourRating = false
      this.showGuiderRating = true
    },
    submitFeedback() {
      const _self = this
      this.feedbackDetail.travel_list_id = this.currentTravelList.id
      switch (this.feedbackDetail.feedbackable_type) {
        case "Guide": _self.feedbackDetail.feedbackable_id = _self.currentTravelList.guider.id
          break
        case "TravelList": _self.feedbackDetail.feedbackable_id = _self.currentTravelList.id
          break
        case "TravelCompany": _self.feedbackDetail.feedbackable_id = _self.currentTravelList.list_company.id 
          break

      }
      api.send_feedback(this.feedbackDetail).then((res) => {
        if(res.status === 200) {
          _self.showFeedbackPanel = false
          _self.feedbackTypeSelectShow = false
        }
      })
    },
    onFeedbackClick(type) {
      const _self = this
      this.feedbackDetail.feedbackable_type = type
      this.showFeedbackPanel = true
      this.cleanFeedBack(type)
      this.feedback_tags.forEach((tags) => {
        if(tags.type === _self.feedbackDetail.feedbackable_type) {
          _self.filtered_feedback_tags = tags.value
          _self.feedback_selection_states = tags.value.map((tag) => {return false})
        }
      })
    },
    onFeedbackTypeSelect(item) {
      this.currentFeedbackTypeText = item.name
      this.onFeedbackClick(item.value)
    },
    onSelectionUpdate(value) {
      this.feedbackDetail.tag_ids = value
    },
    cleanFeedBack(type) {
      this.feedbackDetail = {
        feedback_type: '1',
        feedback_content: "",
        rate_star: 0,
        feedbackable_type: type,
        feedbackable_id: 0,
        feedback_type: 1,
        travel_list_id: 0,
        tag_ids: []
      }
    },
    joinTour() {
      const _self = this
      Dialog.confirm({
        title: '操作确认',
        message: '是否备案上报该行程?',
        cancelButtonText: '填写上报信息',
        confirmButtonText: '直接入团'
      }).then(() => {
        api.travel_lists_add_tourist(_self.currentTravelList.id).then((res) => {
          if(res.status === 200) {
            Toast('成功加入该行程')
            _self.$store.dispatch('travel_list/getAllTravelLists', '0,1,2,3')
            _self.$router.push('/tour/main')
          }
        })
      }).catch(() => {
        _self.$router.push(`/tour/backup/${_self.currentTravelList.id}`)
      })
    }
  }
}
</script>

<style scoped>
.row {
  font-size: 14px;
  color: grey;
}
.tour_title {
  font-size: .3rem;
  font-weight: bold;
}
.content {
  padding: 16px 16px 160px;
  text-align: center;
}
.action_btn {
  position: fixed;
  bottom: 10px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  padding-top: 10px;
  position: fixed;
  bottom: 0px;
  height: 70px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
  z-index: 666;
}
.circle-left {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-color: #436ef0;
  z-index: 10;
  top: 3.5rem;
  left: -0.2rem;
  border-radius: 50%;
}
.circle-right {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-image: linear-gradient(
    90deg,
    #6c94f6 0%,
    #436ef0 16%,
    #3a5be9 100%
  );
  z-index: 10;
  top: 3.5rem;
  right: -0.2rem;
  border-radius: 50%;
}
[v-cloak] {
  display: none;
}
</style>